import {apiAction, dataAction, staticAction, silentApiAction} from "actions/actionWrappers";
import {getRoomDetails, startNewVideo, logVideoRoomEvent, submitConsent as submitConsentApi, getMeetingSummaryRatingStatus} from "api/video";
import {actions} from "reducers/videoReducer";
import {getChatInstance} from "chat/WebSocket";
import {addMessages, addNewMessageToChannel, updateUnreadCount} from "actions/chatAction";
import {getChannelFor} from "business/channel";
import {actions as mentorshipAction} from "reducers/mentorshipReducer";
import moment from "moment";

const startVideo = (channel, isVideoRoomActiveForChatChannel) => async (dispatch) => {
  dispatch(dataAction(actions.START_VIDEO, channel));
  const room = await dispatch(apiAction(startNewVideo, channel.channelId));
  dispatch(dataAction(actions.STORE_ROOM_DETAILS, {room, channel}));
  const chatInstance = getChatInstance();
  chatInstance.addCallbacks((messages, nextPage, type) => dispatch(addMessages(messages, nextPage, type)),
    (message) => dispatch(addNewMessageToChannel(message)), (message) => dispatch(updateUnreadCount(message)));
  if (!isVideoRoomActiveForChatChannel) {
    await chatInstance.newChatMessage(
        {text: JSON.stringify({room: room.room}), channelId: channel.channelId, attachments: [], tempId: null}
      );
  }
};

const joinVideo = (roomId) => async (dispatch, getState) => {
  dispatch(dataAction(actions.START_VIDEO, {}));
  const {chat: {chatChannels} } = getState();
  const room = await dispatch(apiAction(getRoomDetails, roomId));
  dispatch(dataAction(actions.STORE_ROOM_DETAILS, {room, channel: getChannelFor(room.channel, chatChannels)}));
};

const logVideoEvent = (roomId, eventName, jitsiParticipantId) => (dispatch) => {
  if (jitsiParticipantId) {
    dispatch(apiAction(logVideoRoomEvent, roomId, eventName, jitsiParticipantId, moment().toISOString(true)));
  }
}

const callEnded = () => dispatch => dispatch(staticAction(actions.CALL_ENDED));

const submitConsent = (roomId, consent) => async (dispatch) => {
  await dispatch(apiAction(submitConsentApi, roomId, consent));
};

const getMeetingRatingStatus = () => async (dispatch, getState) => {
  try {
    const {auth: {user: {id}}} = getState();
    const meetingSummaryRating = await dispatch(silentApiAction(getMeetingSummaryRatingStatus, id));
    dispatch(dataAction(mentorshipAction.GET_MEETING_SUMMARY_RATING_STATUS, {meetingSummaryRating}))
  } catch (e) {
    console.log(e);
  }
}

export {
  startVideo,
  callEnded,
  joinVideo,
  logVideoEvent,
  submitConsent,
  getMeetingRatingStatus,
};
