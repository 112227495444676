import {isMentee} from "business/role";
import config from 'common/config'
import hexRgb from "hex-rgb"
import { filter, find, get, isNull, omitBy } from 'lodash'
import moment from "moment"

const {API_BASE_URL} = config;


export const omitNulls = d => omitBy(d, isNull);


export const userTypes = {
  MENTOR: 'Mentor',
  MENTEE: 'Mentee'
}


export const accountType = {
  GOOGLE: 'google',
  NORMAL: 'normal'
}

export const isEmailValid = (email) => {
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(String(email).toLowerCase())) {
    return (true)
  }
  return (false)
};

export const isNumberKey = (e) => {
  let result = false;
  try {
    let charCode = (e.which) ? e.which : e.keyCode;
    if ((charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105)) {
      result = true;
    }
  }
  catch (err) {
    //console.log(err);
  }
  return result;
};

export const parseUrl = (url) => {
  const match = url.match(/^(([^:\/?#]+:)?(?:\/\/((?:([^\/?#:]*):([^\/?#:]*)@)?([^\/?#:]*)(?::([^\/?#:]*))?)))?([^?#]*)(\?[^#]*)?(#.*)?$/),
    parts = {
      hash: match[10] || "",                   // #asd
      host: match[3] || "",                    // localhost:257
      hostname: match[6] || "",                // localhost
      href: match[0] || "",                    // http://username:password@localhost:257/deploy/?asd=asd#asd
      origin: match[1] || "",                  // http://username:password@localhost:257
      pathname: match[8] || (match[1] ? "/" : ""), // /deploy/
      port: match[7] || "",                    // 257
      protocol: match[2] || "",                // http:
      search: match[9] || "",                  // ?asd=asd
      username: match[4] || "",                // username
      password: match[5] || ""                 // password
    };
  if (parts.protocol.length === 2) {
    parts.protocol = "file:///" + parts.protocol.toUpperCase();
    parts.origin = parts.protocol + "//" + parts.host;
  }
  parts.href = parts.origin + parts.pathname + parts.search + parts.hash;
  return match && parts;
};

export const convertUrl = (url) => url;

export const humanizedTime = (timeInMinutes) => moment.duration(timeInMinutes, 'minutes').humanize();
export const formatChatTimestamp = (timestamp) => moment(timestamp).format("MMM, D h:mm a");

export const withOpacity = ({red, green, blue}, opacity) => `rgba(${red}, ${green}, ${blue},${opacity})`;
export const transparent = color => withOpacity(hexRgb(color), 0);
export const opaque = color => withOpacity(hexRgb(color), 1);

export const NAV_BAR_HEIGHT = '48px';
export const PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER = `calc(100vh - ${NAV_BAR_HEIGHT} - 60px)`;
export const SM_PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER = `calc(100vh - ${NAV_BAR_HEIGHT} - 140px)`;
export const PAGE_HEIGHT_WITH_NAVIGATION_CONTENT_CONTAINER = `calc(100vh - ${NAV_BAR_HEIGHT} - 92px)`;
export const PAGE_HEIGHT_WITH_NAVBAR = `calc(100vh - ${NAV_BAR_HEIGHT})`;

export const getMemberName = (userId, channelMembers, botUsers) => {
  const userIdStr = userId.toString();

  const channelMember = channelMembers.filter(member => member.userId.toString() === userIdStr)[0];
  if (channelMember) {
    return get(channelMember, "userFullName", "");
  }

  const botUser = botUsers.filter(bot => bot.userId.toString() === userIdStr)[0];
  if (botUser) {
    return get(botUser, "userFullName", "");
  }

  return "Staff";
};

export const getPartner = (members,myId) => get(filter(members, member => member.userId !== myId), "[0]");

export const getPartnerDetails = (role, partnerDetails) => isMentee(role)? partnerDetails.mentor: partnerDetails.mentee;

export const getStartTimeInStandardFormat = (time) => convertUtcToLocal(createUtcDateTime(time.startDate,time.startTime));
export const getEndTimeInStandardFormat = (time) =>convertUtcToLocal(createUtcDateTime(time.endDate,time.endTime));

export const firebaseSignInErrors = {
  USER_ID_DISABLED: "auth/user-disabled",
  INVALID_EMAIL_ADDRESS: "auth/invalid-email",
  USER_DOES_NOT_EXIST: "auth/user-not-found",
  WRONG_PASSWORD: "auth/wrong-password",
};

export const isTemporarilyBlockedDueToManyFailedLogins = (errorResponse) => {
  return (errorResponse.code === 'auth/unknown' || errorResponse.code === 'auth/too-many-requests')
    && errorResponse.message.includes("Access to this account has been temporarily disabled due to many failed login attempts");
};

const createUtcDateTime = (date, time) => moment.utc(`${date + " " + time}`).format("YYYY-MM-DD hh:mm:ss a");
const convertUtcToLocal = (utcDateTime) => moment.utc(utcDateTime).local().format("YYYY-MM-DD hh:mm:ss a");

export const isNewNotificationSupported = () => {
  if (!window.Notification || !Notification.requestPermission) {
    return false;
  }
  if (Notification.permission === 'granted') {
    console.error('isNewNotificationSupported Must be called *before* calling Notification.requestPermission()');
    return true;
  }
  try {
    new Notification('');
  } catch (e) {
    // console.log('isNewNotificationSupported catch', e.name);
    if (e.name === 'TypeError')
      return false;
  }
  return true;
}

export const truncateFileName = (fileName, characterLimit=15) => {
  const extension = fileName.split('.').pop();
  const nameWithoutExtension = fileName.slice(0, -(extension.length + 1));
  if (nameWithoutExtension.length <= characterLimit) {
    return nameWithoutExtension;
  } else {
    const truncatedName = fileName.substring(0, characterLimit) + '...';
    return truncatedName
  }
}

export const getFileExtensionFromName = (fileName) => {
  return fileName.split('.').pop();
}

export const getMemberDisplayPicture = (userId, channelMembers, botUsers) => {
  const userData = channelMembers.find((member) => member.userId.toString() === userId)
  || botUsers.find((bot) => bot.userId.toString() === userId)
  || null;
  return userData ? userData.displayPicture : null;
};

export const getChannelMemberData = (userId, channelMembers, botUsers) => {
  const user = channelMembers.find(member => member.userId === parseInt(userId, 10)) || botUsers.find(bot => bot.userId === parseInt(userId, 10));

  if (user) {
    const { displayPicture, userFullName, userId } = user;

    return {
      displayPicture,
      userFullName,
      userId,
    };
  }

  return {
    displayPicture: null,
    userFullName: '',
    userId: null,
  };
};

export const searchChannelsByName = (chatChannels, searchText, myId) => {
  const uniqueChannelIds = new Set();

  return chatChannels.filter((channel) => {
    if (!uniqueChannelIds.has(channel.channelId)) {
      const isMatched =
        channel.isGroup &&
        channel.channelName.toLowerCase().includes(searchText.toLowerCase());

      const matchingMember = channel.channelMembers.find(
        (member) =>
          member.userId !== myId &&
          member.userFullName.toLowerCase().includes(searchText.toLowerCase())
      );

      if (isMatched || matchingMember) {
        uniqueChannelIds.add(channel.channelId);
        return true;
      }
    }

    return false;
  });
};

export const hasActiveVideoRoomInChat = (messages) => {
  const now = moment();

  return Object.values(messages).some(msg => {
    if (!msg?.message) return false;

    let parsedMessage = {};

    try {
      parsedMessage = JSON.parse(msg.message);
    } catch (error) {
      return false;
    }

    if (parsedMessage?.room) {
      return now.isBefore(moment(msg.created_on).add(3, 'h'));
    }

    return false;
  });
};

