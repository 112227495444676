import React, { useEffect, useState } from 'react';
import { getToken, onMessageListener } from "../firebase/firebase-messaging";
import WebNotification from 'react-web-notification';
import { isNewNotificationSupported } from "common/helpers";

const PushNotification = ({ storeDeviceToken, isAuthenticated }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState(null);
  const [ringtone, setRingtone] = useState(null);

  useEffect(() => {
    if (isNewNotificationSupported() && isAuthenticated) {
      getToken(storeDeviceToken);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const unsubscribe = onMessageListener()
      .then(payload => {
        console.log("Foreground Push notification payload", payload);
        setNotification(payload);
        setShowNotification(true);

        if (payload?.data?.type === "incoming_call") {
          const audio = new Audio("/ringtone.mp3");
          audio.loop = true;
          audio.play();
          setRingtone(audio);
        }
      })
      .catch(err => console.log("Failed to receive notification: ", err));

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  const handleAcceptCall = () => {
    if (ringtone) {
      ringtone.pause();
      ringtone.currentTime = 0;
    }
    setShowNotification(false);
    window.location.href = "https://test.app.mentortogo.org/chat";
  };

  const handleDeclineCall = () => {
    if (ringtone) {
      ringtone.pause();
      ringtone.currentTime = 0;
    }
    setShowNotification(false);
  };

  return (
    <>
      {showNotification && notification?.data?.type === "incoming_call" ? (
        <div style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          background: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 9999
        }}>
          <div style={{
            background: "white",
            padding: "20px",
            borderRadius: "10px",
            textAlign: "center",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)"
          }}>
            <p>{notification.notification?.title || "Incoming Call"}</p>
            <button onClick={handleAcceptCall(notification?.data?.channel)} style={{
              margin: "10px",
              padding: "10px 20px",
              fontSize: "16px",
              cursor: "pointer",
              backgroundColor: "green",
              color: "white",
              border: "none",
              borderRadius: "5px"
            }}>Accept</button>
            <button onClick={handleDeclineCall} style={{
              margin: "10px",
              padding: "10px 20px",
              fontSize: "16px",
              cursor: "pointer",
              backgroundColor: "red",
              color: "white",
              border: "none",
              borderRadius: "5px"
            }}>Decline</button>
          </div>
        </div>
      ) : (
        showNotification && (
          <WebNotification
            options={notification}
            onShow={() => console.log('Notification shown')}
            onClick={() => setShowNotification(false)}
            onClose={() => setShowNotification(false)}
            onError={(e) => console.log('Notification onError', e)}
            title={notification.title}
          />
        )
      )}
    </>
  );
};

export default PushNotification;
