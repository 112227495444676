import React, {useEffect, useState, useRef} from "react";
import MessageWindowHeader from "components/Chat/MessageWindowHeader";
import ChatMessageWrapper from "components/Chat/ChatMessageWrapper";
import GroupMemberList from "components/Chat/GroupMemberList";
import withStyles from "@material-ui/core/styles/withStyles";
import {messageWindowSubTitle, messageWindowTitle, getChannelFor} from "business/channel";
import {history} from "common/history";
import {useTranslation} from "react-i18next";
import { find, isEmpty } from "lodash";
import MessageInputBox from "components/Chat/MessageInputBox";
import moment from "moment";
import {getEndTimeInStandardFormat} from "common/helpers";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import ImagePreviewModal from "components/Chat/ImagePreviewModal";
import ChatCalendarModal from "./ChatCalendarModal";
import config from "common/config";
import ChatRatingWrapper from "./ChatRatingWrapper";
import {hasActiveVideoRoomInChat} from "../../common/helpers"

const styles = () => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%'
  },
  chatMessageWindowWrapper:{
    overflowY: 'hidden',
  },
  wrapperFullScreen: {
    position: 'fixed',
    height: '100%',
    left: 0,
    right: 0,
    top: 0,
    boxSizing: 'initial',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  messageBoxContainer: {
    position: 'relative',
  }
});

const ChatMessageWindow = ({
  classes, onBackPress, onSendClick, myId, myEmail, messages, style, addNewMessageToChannel,
  onExpandBtnPress, loadMoreMessages, hasMore, isFullScreen, onChatMemberClick, channelsWithMemberId,
  showCloseBtn, closeBtnAction, showBackBtn, onViewMessage, chatChannels, selectedChannelId, selectedMessageId,
  setSelectedMessageId, setIsMessageSelected, isMessageSelected, botUsers, updateUserChatRating, getChatRatingModalStatus, chatRatingStatus, getChannelHistory, chatChannelHistory, storeMeetingSummaryRating, isSmallScreen = true,
}) => {

  const {t} = useTranslation();
  const { AI_ROLE_TYPE } = config;

  const [channel, setChannel] = useState(getChannelFor(selectedChannelId, chatChannels))
  const [isVideoRoomActiveForChatChannel, setIsVideoRoomActiveForChatChannel] = useState(false);
  const chatBotUser = botUsers.find(botUser => botUser.roleType === AI_ROLE_TYPE);
  const isAIChannel = channel.channelMembers.some(member => member.userId === chatBotUser.userId);
  const title = messageWindowTitle(channel, myId, isAIChannel);
  const subTitle = t(messageWindowSubTitle(channel, myId));
  const getChannelIdFor = (userId) => find(channelsWithMemberId, channel => channel.userId === userId).channelId;
  const isMobileView = useMediaQuery('(max-width:600px)');
  const [openCalendarModal, setOpenCalendarModal] = useState(false);
  const [isRatingModal, setIsRatingModal] = useState(false);
  const [isSuccessPopup, setIsSuccessPopup] = useState(false);
  const [isNewMessageLoading, setIsNewMessageLoading] = useState(false);
  const ratingModalCountdownDuration = 5 * 60 * 1000;
  const ratingCountdownTimer = useRef(null);

  const handleChatClick = (userId) => {
    onBackPress();
    onChatMemberClick(getChannelIdFor(userId))
  }

  const activeBlackoutEvent = null; // isBlackoutPeriod(new Date(), chatBlackoutPeriod);
  const isBlackOutPeriodActive = false; //activeBlackoutEvent.length > 0;
  const isCurrentUserInactiveInChannel = isEmpty(channel.channelMembers) ||
    isEmpty(channel.channelMembers.find(member => member.userId === myId)) ||
    channel.channelMembers.find(member => member.userId === myId).isActive === false;

  const [showMembers, setShowMembers] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImageUri, setSelectedImageUri] = useState('');

  const showImagePreview = (uri) => {
    setSelectedImageUri(uri);
    setShowImageModal(true);
  };

  const closeImageModal = () => {
    setShowImageModal(false);
    setSelectedImageUri('');
  };

  useEffect(() => {
    onViewMessage(selectedChannelId);
  }, [messages, selectedChannelId]);

  useEffect(() => {
    setIsVideoRoomActiveForChatChannel(hasActiveVideoRoomInChat(messages));
}, [messages]);

  useEffect(() => {
    setChannel(getChannelFor(selectedChannelId, chatChannels))
  }, [chatChannels]);

  const handleOpenCalender = () => {
    setOpenCalendarModal(true);
  };

  const submitUserChatRating = (ratingsData) => {
    updateUserChatRating(selectedChannelId, ratingsData);
    return true;
  }

  const OnRatingModalOpen = async () => {
    await getChatRatingModalStatus(selectedChannelId);
    if(chatRatingStatus && chatRatingStatus.isRatingModal) {
      setIsRatingModal(true);
    } else {
      setIsRatingModal(false);
    }
  }

  useEffect(() => {
    if (selectedChannelId) {
      getChatRatingModalStatus(selectedChannelId);
      getChannelHistory(selectedChannelId);
    }
    if (ratingCountdownTimer.current) {
      clearTimeout(ratingCountdownTimer.current);
    }

    setIsRatingModal(false);
  }, [selectedChannelId]);

  useEffect(() => {
    if (chatRatingStatus && chatRatingStatus.isRatingModal) {
      setIsRatingModal(true);
    } else {
      setIsRatingModal(false);
      setIsSuccessPopup(false);
    }
  }, [chatRatingStatus]);

  const onStartRatingCountDown = () => {
    if (ratingCountdownTimer.current) {
      clearTimeout(ratingCountdownTimer.current);
    }
    console.log("Rating Countdown started");

    ratingCountdownTimer.current = setTimeout(() => {
      OnRatingModalOpen();
    }, ratingModalCountdownDuration);
  }

  return (
    <>
    <div className={isMobileView ? classes.wrapperFullScreen : classes.wrapper} style={style}>
      <div>
        <MessageWindowHeader
          windowTitle={title}
          onBackPress={onBackPress}
          subTitle={subTitle}
          onExpandBtnPress={onExpandBtnPress}
          isFullScreen={isFullScreen}
          showFullScreenButton={!isFullScreen}
          onFullScreenRequested={() => history.push(`/chat/messenger`)}
          showExpandBtn={true}
          onSubTitleClick={() => setShowMembers(true)}
          isLoading={false}
          channel={channel}
          isBlackOutPeriodActive={isBlackOutPeriodActive}
          showCloseBtn={showCloseBtn}
          closeBtnAction={closeBtnAction}
          showBackBtn={showBackBtn}
          showVideoBtn={!isCurrentUserInactiveInChannel}
          isAIChannel={isAIChannel}
          isVideoRoomActiveForChatChannel={ isVideoRoomActiveForChatChannel }
        />
      </div>

      {showMembers ?
        <div>
          <GroupMemberList
            members={channel.channelMembers}
            onClose={() => setShowMembers(false)}
            myId={myId}
            onChatClick={(userId) => handleChatClick(userId)}
            isAIChannel={isAIChannel}
            chatChannelHistory={chatChannelHistory}
            storeMeetingSummaryRating={storeMeetingSummaryRating}
            isSmallScreen={isSmallScreen}
          />
        </div>
        :
        <>
          <div className={classes.chatMessageWindowWrapper}
            id="scrollableDiv"
            style={{overflow: 'hidden', display: 'flex', flexDirection: 'column-reverse'}}
          >
            <ChatMessageWrapper
              messages={messages || []}
              isFullScreen={isFullScreen}
              myId={myId}
              hasMore={hasMore}
              channelMembers={channel.channelMembers}
              isBlackOutPeriodActive={isBlackOutPeriodActive}
              loadMoreMessages={loadMoreMessages}
              previewImage={showImagePreview}
              channel={channel}
              chatChannels={chatChannels}
              selectedChannelId={selectedChannelId}
              selectedMessageId={selectedMessageId}
              setSelectedMessageId={setSelectedMessageId}
              setIsMessageSelected={setIsMessageSelected}
              isMessageSelected={isMessageSelected}
              botUsers={botUsers}
              handleOpenCalender={handleOpenCalender}
              isAIChannel={isAIChannel}
              isNewMessageLoading={isNewMessageLoading}
              setIsNewMessageLoading={setIsNewMessageLoading}
              chatBotUser={chatBotUser}
            />
          </div>

          <div className={classes.messageBoxContainer}>
            {isBlackOutPeriodActive || isCurrentUserInactiveInChannel ?
              <BlackoutInputWindow event={activeBlackoutEvent}/> :
              <MessageInputBox
                onSendClick={onSendClick}
                myId={myId}
                myEmail={myEmail}
                channelId={channel.channelId}
                isFullScreen={isFullScreen}
                addNewMessageToChannel={addNewMessageToChannel}
                channelMembers={channel.channelMembers
                }
                setSelectedMessageId={setSelectedMessageId}
                isAIChannel={isAIChannel}
                onStartRatingCountDown={onStartRatingCountDown}
                setIsNewMessageLoading={setIsNewMessageLoading}
              />
            }
            {isRatingModal && <ChatRatingWrapper classes={classes} isFullScreen={isFullScreen} updateUserChatRating={(ratingsData) => submitUserChatRating(ratingsData)} isSuccessPopup={isSuccessPopup} setIsSuccessPopup={setIsSuccessPopup} setIsRatingModal={setIsRatingModal} />}
          </div>
          <ImagePreviewModal
            visible={showImageModal}
            imageUrl={selectedImageUri}
            onClose={closeImageModal}
          />
        </>
      }
      <ChatCalendarModal
        open={openCalendarModal}
        handleClose={() => setOpenCalendarModal(false)}
      />
    </div>
    </>
  );
};

export default withStyles(styles)(ChatMessageWindow);


const BlackoutInputWindow = ({event}) => {
  const {t} = useTranslation();
  const endTime = event && moment(getEndTimeInStandardFormat(event[0])).format("hh:mm A");

  return (<div style={{
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: 12,
    padding: '12px 24px'
  }}>
    <div style={{fontWeight: 'bold', marginBottom: 2}}>{t("Chat & Video Disabled")}</div>
    {endTime && <div
      style={{
        color: 'rgba(255, 255, 255, 0.65)',
        textAlign: 'center'
      }}>{`${t("You will be able to message again from")} ${endTime}`}
    </div>}
  </div>)
};
