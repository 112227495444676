import withStyles from "@material-ui/core/styles/withStyles";
import React, {useEffect, useState} from "react";
import * as propTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar/Avatar";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import classNames from "classnames";
import { formatChatTimestamp } from "common/helpers";
import VideoTypeMessage from "components/Chat/VideoTypeMessage";
import { history } from "common/history";
import { truncateFileName, getFileExtensionFromName } from "common/helpers";
import { isEmpty, get } from "lodash";
import FileUploadAlt from 'common/assets/FileUploadAlt.png';
import DocFile from 'common/assets/DocFile.svg';
import XlsFile from 'common/assets/XlsFile.svg';
import PptFile from 'common/assets/PptFile.svg';
import PdfFile from 'common/assets/PdfFile.png';

const styles = theme => ({
  wrapper: {
    display: 'flex',
    margin: '8px 0px',
    flexShrink: 0,
  },
  messageContentWrapper: {
    background: '#fff',
    padding: 12,
    maxWidth: '80%',
    position: 'relative',
    marginLeft: 17,
    marginRight: 17,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  messageContainerWrapperForCurrentUser: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 0,
  },
  messageContainerWrapperForOtherUser: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 20,
  },
  messageTime: {
    fontSize: 9,
    color: 'rgba(0, 0, 0, 0.2);',
    lineHeight: '12px',
  },
  name: {
    fontWeight: 'bold',
    color: '#7C5F0E',
    fontSize: 9,
    lineHeight: '11px',
    marginBottom: 4,
    marginRight: 4,
  },
  avatarWrapper: {
    padding: '0 4px',
  },
  seenAvatar: {
    padding: '0 2px',
  },
  seenAvatarContainer: {
    display: "flex",
    flexShrink: 0,
    position: "absolute",
    right: 10,
    bottom: -10,
  },
  receiptAvatar: {
    width: 16,
    height: 16,
  },
  messageContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '90%',
    position: 'relative',
  },
  alignRight: {
    alignItems: 'flex-end',
  },
  justifyRight: {
    justifyContent: 'flex-end',
  },
  justifyLeft: {
    justifyContent: 'flex-start'
  },
  smallAvatar: {
    width: 28,
    height: 28
  },
  messageText: {
    fontSize: 12,
    lineHeight: '14px',
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-line',
    marginBottom: 8,
  },
  messageAttachmentName: {
    fontWeight: '500',
    fontSize: 11,
    color: 'black',
    marginRight: 3
  },
  messageAttachmentExtension: {
    fontWeight: '700',
    fontSize: 11,
    color: '#E0B334',
    display: 'flex',
    alignItems: 'center',
  },
  messageAttachmentNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap'
  },
  attachmentDownloadIcon: {
    fontSize: 15,
    marginLeft: 2.5,
    cursor: 'pointer'
  },
  imagePreview: {
    maxWidth: 200,
    width: "100%",
    marginTop: 8,
    cursor: 'pointer'
  },
  sendersBackground: {
    backgroundColor: '#DEE0F0',
  },
  overlayText: {
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: 400,
  },
  overlayContainer: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: '#fff',
    borderRadius: 40,
    position: 'absolute',
    bottom: 20,
    right: 0,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  userProfile: {
    position: 'relative',
  },
  userTaged: {
    fontWeight: '400px',
    color: '#CFA222',
    lineHeight: '120%',
  },
  currentUserTag: {
    fontWeight: '600px',
    color: '#CFA222',
    lineHeight: '120%',
  },
  selectedMessage: {
    border: '1px solid #515DC0'
  },
  assistantMessageContentWrapper: {
    backgroundColor: '#E0B334'
  },
  assistantMessageText: {
    color: '#FFFFFF'
  },
  messageDivider: {
    border: 0,
    height: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    margin: '10px 0'
  },
  helperText: {
    color: '#000000',
    fontSize: '9px',
  },
  attachmentList: {
    display: 'flex',
    flexDirection: 'column',
  },
  attachmentItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
  },
  attachmentLabel: {
    fontWeight: '500',
    fontSize: '11px',
    color: '#000000',
  },
  attachmentType: {
    fontSize: '11px',
    marginRight: 5
  },
  attachementContainer: {
    display: 'flex',
    alignItems: 'center',
    color: '#FFFFFF',
  },
  messageHeader: {
    display: 'flex',
  }
});

const TextTypeMessage = ({ classes, messageContent, messageDate, userName, myId, handleOpenCalender, isAIChannel, isSelf, isBroadcastMessage, messageAttachments }) => {
  const renderParsedMessage = () => {
    const mentionRegex = /@\[([^]+?)\]\((\d+)\)/g;
    let parsedMessage = messageContent.trim().replace(/^"(.*)"$/, '$1');
    const unicodeToChar = (text) => {
      return text.replace(/\\u([\dA-Fa-f]{4})/g, (_, p1) =>
        String.fromCharCode(parseInt(p1, 16))
      );
    };
    parsedMessage = unicodeToChar(parsedMessage);
    let parsedData;
    while ((parsedData = mentionRegex.exec(messageContent)) !== null) {
      const mentionText = parsedData[1];
      const userId = parsedData[2];
      const isMyIdTagged = Number(userId) === myId;
      const textColor = isMyIdTagged ? '600' : '400';

      const replacement = `<span style="color: #CFA222; line-height: 120%; font-weight: ${textColor};">@${mentionText}</span>`;

      parsedMessage = parsedMessage.replace(parsedData[0], replacement);
    }

    const calendarRegex = /\{\{calendar\}\}/g;
    parsedMessage = parsedMessage.replace(calendarRegex, () => {
      return '<span class="calendar-link" style="color: blue; cursor: pointer;">calendar</span>';
    });
    const isMessageContainsCalendarLink =  messageContent.includes('{{calendar}}')

    return (
      <div
        dangerouslySetInnerHTML={{ __html: parsedMessage }}
        onClick={() => isMessageContainsCalendarLink && handleOpenCalender()}
      />
    );
  };

  return (
    <>
      <div className={classes.messageHeader}>
        <div className={classes.name}>{userName}</div>
        <div className={classNames(classes.messageTime, (isAIChannel && !isSelf) && classes.assistantMessageText)}>
          {formatChatTimestamp(messageDate)}
        </div>
      </div>
      {!isEmpty(messageContent) && (
        <>
          <div className={classNames(classes.messageText, ((isAIChannel && !isSelf) || isBroadcastMessage) && classes.assistantMessageText)}>
            {renderParsedMessage()}
          </div>
          {isBroadcastMessage && isEmpty(messageAttachments) && (
            <>
              <hr className={classes.messageDivider} />
              <div className={classes.helperText}>
                Tap on the group name for previous transcripts and summaries
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};


const isJson = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false;
  }
  return isNaN(parseInt(str));
};

const handleDownloadImage = (file_url) => {
  window.open(file_url);
}

const Message = (props) => {
  const {classes, messageContent, messageStatus, messageAttachments, isSelf,  onClickImage, displayPicture, membersData, isSelected, isAIChannel, isNewMessageLoading, setIsNewMessageLoading, reverseOrderedMessageLength, isBroadcastMessage} = props;

  const [hoveredUserId, setHoveredUserId] = useState(null);
  const handleUserHover = (userId) => {
    setHoveredUserId(userId);
  };

  useEffect(() => {
    if (isSelf && isNewMessageLoading && isAIChannel) {
      setIsNewMessageLoading(false);
    }
  }, [isSelf, reverseOrderedMessageLength]);

  const handleUserLeave = () => {
    setHoveredUserId(null);
  };

  return (
    <>
      <div>
        <div className={classNames(classes.wrapper, isSelf ? classes.justifyRight : classes.justifyLeft)}>
          {!isSelf &&
            <div className={classes.avatarWrapper}>
              <Avatar src={displayPicture} className={classes.smallAvatar}/>
            </div>
          }
          <div className={classNames(classes.messageContentContainer, isSelf && classes.alignRight)}>
            <div className={classNames(classes.messageContentWrapper, ((isAIChannel && !isSelf) || (isBroadcastMessage)) && classes.assistantMessageContentWrapper,  isSelf ? classes.messageContainerWrapperForCurrentUser : classes.messageContainerWrapperForOtherUser, isSelf && classes.sendersBackground, isSelected && classes.selectedMessage)}>
              {isJson(messageContent) && !isEmpty(get(JSON.parse(messageContent), 'room'))
                ? <VideoTypeMessage {...props}
                    onClick={() => {
                      history.push(`/chat/messenger?section=video`)
                    }}
                  />
                : <TextTypeMessage {...props}/>
              }
              {
                !isEmpty(messageAttachments) && (
                  <div>
                    {isBroadcastMessage ? (
                      <div>
                        <div className={classes.attachmentList}>
                          {messageAttachments.map((attachment) => (
                            <div key={attachment.id}>
                              <hr className={classes.messageDivider} />
                              <div className={classes.attachmentItem}>
                                <span className={classes.attachmentLabel}>
                                  {attachment.attachment_name.includes("summary") ? "Summary" : "Transcript"}
                                </span>
                                <div className={classes.attachementContainer}>
                                  <span className={classes.attachmentType}>.txt</span>
                                  <SaveAltIcon
                                    className={classes.attachmentDownloadIcon}
                                    onClick={() => handleDownloadImage(attachment.presigned_url)}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      messageAttachments.map(attachment => {
                        const thumbnailAvailableForFiles = [
                          'png', 'jpg', 'jpeg', 'gif', 'webp', 'heic', 'pdf', 'xlsx', 'xls', 'ppt', 'pptx', 'doc', 'docx'
                        ];
                        const fileExtension = getFileExtensionFromName(attachment.attachment_name).toLowerCase();
                        if (!thumbnailAvailableForFiles.includes(fileExtension)) {
                          return <></>;
                        }

                        const isPdf = ['pdf'].includes(fileExtension);
                        const isGif = ['gif'].includes(fileExtension);
                        const isDoc = ['doc', 'docx'].includes(fileExtension);
                        const isXls = ['xls', 'xlsx'].includes(fileExtension);
                        const isPpt = ['ppt', 'pptx'].includes(fileExtension);

                        return (
                          attachment.thumbnail_presigned_url || attachment.url ? (
                            <img
                              alt="Preview not available"
                              className={classes.imagePreview}
                              src={isGif ? (attachment.url || attachment.presigned_url) : attachment.thumbnail_presigned_url}
                              key={attachment.id}
                              onClick={() =>
                                isPdf
                                  ? handleDownloadImage(attachment.presigned_url)
                                  : onClickImage(attachment.url || attachment.presigned_url)
                              }
                            />
                          ) : messageStatus === 'completed' ? (
                            (isDoc || isXls || isPpt || isPdf) ? (
                              <img
                                alt="Preview not available"
                                className={classes.imagePreview}
                                src={isDoc ? DocFile : isXls ? XlsFile : isPpt ? PptFile : PdfFile}
                                key={attachment.id}
                                onClick={() => handleDownloadImage(attachment.presigned_url)}
                              />
                            ) : (
                              <></>
                            )
                          ) : (
                            <img
                              alt="Preview not available"
                              className={classes.imagePreview}
                              src={FileUploadAlt}
                              key={attachment.id}
                            />
                          )
                        );
                      })
                    )}
                    {messageStatus === 'completed' ? (
                      <></>
                    ) : (
                      <LinearProgress />
                    )}
                    {messageAttachments.map(attachment => (
                      !attachment?.url && (
                        <div
                          className={classes.messageAttachmentNameContainer}
                          key={attachment.id}
                        >
                          <span className={classes.messageAttachmentName}>
                            {truncateFileName(attachment.attachment_name, 13)}
                          </span>
                          <span className={classes.messageAttachmentExtension}>
                            .{getFileExtensionFromName(attachment.attachment_name)}
                            {messageStatus === 'completed' && attachment.presigned_url && (
                              <SaveAltIcon
                                className={classes.attachmentDownloadIcon}
                                onClick={() => handleDownloadImage(attachment.presigned_url)}
                              />
                            )}
                          </span>
                        </div>
                      )
                    ))}
                  </div>
                )
              }
              {membersData.length > 0 && (
                <div className={classes.seenAvatarContainer}>
                  {membersData.map((member, index) => (
                    <div className={classes.userProfile} key={index}>
                      <div
                        className={classes.seenAvatar}
                        key={member.userId}
                        onMouseEnter={() => handleUserHover(member.userId)}
                        onMouseLeave={handleUserLeave}
                      >
                      <Avatar src={member.displayPicture} className={classes.receiptAvatar} />
                      </div>
                      {hoveredUserId === member.userId && (
                        <div className={classes.overlayContainer}>
                          <div className={classes.overlayText}>
                            {member.userFullName}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default withStyles(styles)(Message);

Message.propTypes = {
  messageContent: propTypes.string,
  messageDate: propTypes.string,
  isSelf: propTypes.bool,
  userName: propTypes.string
};

Message.defaultProps = {
  isSelf: true
};
